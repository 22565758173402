import React from "react"

import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import Img from "gatsby-image"

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";

// import required modules
import { Mousewheel, Pagination, Autoplay } from "swiper";


const SliderSwiper = ({ data, center, oneSlideBorder, mobile, home, top }) => {

  var oneBorder = true
  if (data.length < 2)
    oneBorder = false

  if (home && mobile)
    oneBorder = true
  if (!center) center = false

  var titleClass = ' m-0 mb-1 '
  if (top) {
    titleClass = titleClass + ' title '
  }

  var paramsWithoutBorder
  var paramsOneSlideBorder
  if (mobile) {
    paramsOneSlideBorder = {
      direction: "horizontal",
      slidesPerView: 1,
      modules: [Pagination],
      loop: oneBorder,
      grabCursor: oneBorder,
      className: "mySwiper",
      pagination: {
        clickable: true,
        currentClass: 'bullet-active',
        renderBullet: (index, className) => {
          return '<span class="' + className + '">'
            + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
            + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
            + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
            + '</svg></span>';
        }
      }
    }
    paramsWithoutBorder = {
      direction: "horizontal",
      slidesPerView: 1,
      autoplay: {
        delay: 9000,
        disableOnInteraction: false
      },
      enabled: false,
      grabCursor: false,
      loop: false,
      modules: [Mousewheel, Pagination, Autoplay],
      className: "mySwiper swiper-without-border",
      pagination: {
        clickable: false,
        currentClass: 'bullet-active',
        renderBullet: (index, className) => {
          return '<span class="' + className + '">'
            + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
            + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
            + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
            + '</svg></span>';
        }
      }
    }
    return (
      <SliderStyled centered={center ? true : false} oneSlide={oneSlideBorder ? true : false} >
        {oneSlideBorder ?
          <SlideStyled big={'5vh'} home={home ? true : false}>
            <Swiper {...paramsOneSlideBorder}>
              {data.map((value, index) => (
                <SwiperSlide key={'slide-one-slide-border-1-' + index}>
                  <div className='container-slide' >
                    <div className={center ? "text-center container-text-title" : "container-text-title"}>
                      {value.title.map((index, key) => (
                        <h1 key={index} className={font.oL + titleClass}>{value.title[key]}</h1>
                      ))
                      }
                      <div className={center ? "m-auto container-text-desciption" : " container-text-desciption uppercase"}>
                        {value.description && <h4 className={font.oL} dangerouslySetInnerHTML={{ __html: value.description }}></h4>}
                      </div>
                    </div>
                    <Img
                      fluid={value.background.childImageSharp.fluid}
                      alt={value.title.join(' ')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideStyled>
          :
          <SlideStyled big={'4vh'} home={home ? true : false}>
            <Swiper {...paramsWithoutBorder}>
              {data.map((value, index) => (
                <SwiperSlide key={'slide-one-slide-border-2-' + index}>
                  <div className='container-slide' >
                    <div className={center ? "text-center container-text-title" : "container-text-title"}>
                      <h1 className={font.oL + titleClass}>{value.title[0]}</h1>
                      {value.title.length > 1 && <h1 className={font.oL + ' m-0'}>{value.title[1]}</h1>}
                      <div className={center ? "m-auto container-text-desciption" : " container-text-desciption uppercase"}>
                        {value.description && <h4 className={font.oL} dangerouslySetInnerHTML={{ __html: value.description }}></h4>}
                      </div>
                    </div>
                    <Img
                      fluid={value.background.childImageSharp.fluid}
                      alt={value.title.join(' ')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideStyled>
        }
      </SliderStyled>
    )
  }
  else {
    paramsOneSlideBorder = {
      direction: "vertical",
      slidesPerView: 1,
      loop: oneBorder,
      modules: [Pagination],
      className: "mySwiper",
      grabCursor: !oneBorder,
      pagination: {
        clickable: true,
        currentClass: 'bullet-active',
        renderBullet: (index, className) => {
          return '<span class="' + className + '">'
            + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
            + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
            + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
            + '</svg></span>';
        }
      }
    }
    paramsWithoutBorder = {
      slidesPerView: 1,
      enabled: false,
      grabCursor: false,
      loop: false,
      modules: [Mousewheel, Pagination, Autoplay],
      className: "mySwiper",
      pagination: {
        clickable: false,
        currentClass: 'bullet-active',
        renderBullet: (index, className) => {
          return '<span class="' + className + '">'
            + '<svg ' + className + ' width="14.234" height="73.359" viewBox="0 0 14.234 73.359">'
            + '<path  d="M1.269,14.173A2.456,2.456,0,0,1,.806,14.1a1.17,1.17,0,0,1-.585-.344,1.085,1.085,0,0,1-.173-.992L2.317,4.156C2.987,1.611,6.075-.03,10.136,0L14.9.036,73.359.159l-.326,1.4L70.069,12.8a1.8,1.8,0,0,1-.72.993,2.123,2.123,0,0,1-1.2.441Z"'
            + ' transform="translate(0 73.359) rotate(-90)" fill="#ffffff" />'
            + '</svg></span>';
        }
      }
    }
    return (
      <SliderStyled centered={center ? true : false} oneSlide={oneSlideBorder ? true : false} >
        {(oneSlideBorder || home) ?
          <SlideStyled big={oneSlideBorder ? '5vh' : '4vh'} home={home ? true : false} >
            <Swiper {...paramsOneSlideBorder}>
              {data.map((value, index) => (
                <SwiperSlide key={'slide-one-slide-border-1-' + index}>
                  <div className='container-slide' >
                    <div className={center ? "text-center container-text-title" : "container-text-title"}>
                      {value.title.map((index, key) => (
                        <h1 key={index} className={font.oL + titleClass}>{value.title[key]}</h1>
                      ))
                      }
                      <div className={center ? "m-auto container-text-desciption" : " container-text-desciption uppercase"}>
                        {value.description && <h4 className={font.oL} dangerouslySetInnerHTML={{ __html: value.description }}></h4>}
                      </div>
                    </div>
                    <Img
                      fluid={value.background.childImageSharp.fluid}
                      alt={value.title.join(' ')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideStyled>
          :
          <SlideStyled big={oneSlideBorder ? '5vh' : '4vh'} home={home ? true : false}>
            <Swiper {...paramsWithoutBorder}>
              {data.map((value, index) => (
                <SwiperSlide key={'slide-one-slide-border-2-' + index}>
                  <div className='container-slide' >
                    <div className={center ? "text-center container-text-title" : "container-text-title"}>
                      <h1 className={font.oL + titleClass}>{value.title[0]}</h1>
                      {value.title.length > 1 && <h1 className={font.oL + ' m-0'}>{value.title[1]}</h1>}
                      <div className={center ? "m-auto container-text-desciption" : " container-text-desciption uppercase"}>
                        {value.description && <h4 className={font.oL} dangerouslySetInnerHTML={{ __html: value.description }}></h4>}
                      </div>
                    </div>
                    <Img
                      fluid={value.background.childImageSharp.fluid}
                      alt={value.title.join(' ')}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlideStyled>
        }
      </SliderStyled>
    )
  }

}

export default SliderSwiper


const SlideStyled = styled.div`
  height: 100vh;
  width: 100%;
  margin: auto;

  .uppercase{
    text-transform: uppercase !important;
  }
    
  .swiper {
    width: 100%;
    height: 100%;
  }
  .m-0{
    margin: 0;
  }
  .mb-1{
    margin-bottom: 1rem;
  }
  
  .swiper-slide {
    text-align: center;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets,
  .swiper-pagination-horizontal.swiper-pagination-bullets, .swiper-horizontal>.swiper-pagination-bullets{
    left: ${props => props.home ? '0% !important' : '1% !important'} ;
    width: 1%;
    display: grid;
    z-index:99;
    @media (max-width: 990px){
      height: fit-content;
    }
  }

.swiper-pagination-bullet {
    background: transparent;
    opacity: 1; 
    svg{
        height: 100%;
        width: 100%;
        max-height: ${props => props.big && props.big};
    }
    svg path 
    {
        opacity: 1;
        fill: #fff;
    }
}
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: transparent;
    svg{
        height: 100%;
        width: 100%;
        max-height: ${props => props.big && props.big};
    }
    svg path 
    {
        opacity: 1;
        fill: #ab2c29;
    }
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet{
    height: 100%;
    width: 100%;
}

${props => props.home && css`
.swiper-pagination-bullet-active , .swiper-pagination-bullet{
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
}
  .container-text-title{
    line-height:0.7;
  }

  @media(max-width: 990px){
    .container-text-title{
      line-height:1.3;
    }
  }
  `}



${props => props.centered === true &&
    css`
  .swiper-pagination-bullet:only-child{
    display: block !important;
    margin-left: 4vw !important;
  }`
  }

.swiper-pagination-bullet{
  display: block !important;
  margin-left: 4vw !important;
}

`
const SliderStyled = styled.div`

  .container-slide{
    position: relative;
    height: 100vh;
    width: 100%;

    .gatsby-image-wrapper{
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
    .container-text-title{
      position: absolute;
      top: 0;
      left: 20vh;
      text-transform: uppercase;
      z-index: 99;
      height: 100%;
      align-items: center;
      align-self: center;
      align-content: center;
      display: grid;
      text-align: left;
    }
    .container-text-desciption{
      text-transform: initial;
      z-index: 99;
      width: 25vw;
    }
  }

  .text-center{
    text-align: center !important;
    left: 0 !important;
    width: 100% !important;
    .container-text-desciption{
      width: 40% !important;
    }
  }

  .m-auto{
    margin:auto !important;
  }

  
${props => props.oneSlide === true &&
    css`
  .swiper-pagination-bullet:only-child{
    display: block !important;
    margin-left: 4vw !important;
  }`
  }

.swiper-pagination-bullet{
  display: block !important;
  margin-left: 4vw !important;
  svg{
    margin-top: ${props => props.home ? '1.5vh' : '0'};
    margin-bottom: ${props => props.home ? '1.5vh' : '0'};
  }
}

  @media (max-width: 990px){
    .text-center{
      .container-text-desciption{
        width: 60% !important;
      }
    }
    .container-slide{
      height: 100vh;
      .gatsby-image-wrapper{
        height: 100vh;
        width: 100%;
        object-fit: cover;
      }
      .container-text-title{
        left: 10vh;
        width: 70%;

        .container-text-desciption{
          width: 90%;
        }
      }
  }
    
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    margin-bottom: auto;
      height: 100%;
      width: 20%;
      margin-top: ${props => props.oneSlide === true ? '0' : 'auto'};
      align-content: center;
      row-gap: 0vh;
      left: 5% !important;
    }
  }

  ${props => props.centered === true &&
    css`
  .swiper-pagination-bullet:only-child{
    display: none !important;
  }`
  }
`